<template>
  <div>
    <h1 class="h3">
      {{ `${$t('product.listProduct')} - ${$parent.channel.title}` }}
    </h1>
    <div class="row mt-4">
      <div class="col-md-5">
        <a-input-search
          v-model="keyword"
          size="large"
          :placeholder="$t('store_list_page.search_products')"
          :loading="loadingSearch"
          :disabled="isLoading"
          allow-clear
          @search="onSearch"
        >
          <a-button slot="enterButton" type="danger" icon="search">
            {{ $t('utils.search') }}
          </a-button>
        </a-input-search>
      </div>
      <div
        v-if="['tiktok', 'tiktok_id', 'tokopedia_id', 'shopee_id', 'blibli_id', 'lazada_id'].includes($parent.channel.sales_channel?.code)"
        class="col-md-2 p-0"
      >
        <div class="d-flex align-items-center">
          <a-select
            v-model="filterStatus"
            size="large"
            style="width: 205px"
            class="text-capitalize select-antd-default"
            placeholder="Filter Status Produk"
            allow-clear
            :disabled="isLoading"
            @change="handleFilterStatusProduct"
          >
            <a-select-option v-for="item in statusProduct" :key="item" :value="item" class="text-capitalize">
              {{ `${$t('store_list_page.' + item)}` }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div v-else class="col-md-2 p-0">
        <div class="d-flex align-items-center">
          <div class="flexno pr-2">
            {{ `${$t('order.sortBy')}:` }}
          </div>
          <a-select
            v-model="sorting"
            size="large"
            style="width: 145px"
            class="text-capitalize select-antd-default"
            :placeholder="$t('order.sortBy')"
            :disabled="isLoading"
          >
            <a-select-option v-for="sort in sorts" :key="sort" :value="sort" class="text-capitalize">
              {{ sort }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="col-md-5 text-right">
        <a-dropdown v-if="['distributor', 'store'].includes($route.query.channel_code)" :disabled="isLoading">
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="onShowUploadModal">
              {{ $t('product.import_change_price') }}
            </a-menu-item>
            <a-menu-item key="2" @click="exportDataTable">
              {{ $t('product.export_product_data') }}
            </a-menu-item>
          </a-menu>
          <a-button size="large" class="mr-2">
            {{ $t('product.import_export') }} <a-icon type="down" />
          </a-button>
        </a-dropdown>

        <a-button
          v-if="['tiktok', 'tiktok_id', 'tokopedia_id', 'shopee_id', 'blibli_id', 'lazada_id'].includes($parent.channel.sales_channel?.code)"
          size="large"
          type="primary"
          class="mr-3"
          :loading="isLoading"
          @click.prevent="exportDataTable"
        >
          {{ $t('warehouse.export_data') }}
        </a-button>
        <template v-if="['blibli_id'].includes($parent.channel.sales_channel?.code)">
          <a-dropdown :trigger="['click']" arrow placement="bottomRight">
            <a-button
              type="primary"
              size="large"
              class="mr-3"
              ghost
            >
              <a-icon type="bars" />
              Sync Produk
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item key="1a">
                  <a @click="onSyncProduct"><a-icon type="sync" class="mr-2" />Sync Semua Produk</a>
                </a-menu-item>
                <!-- <a-menu-item key="2b">
                  <a @click="showModalSyncProductBlibliBySKU = true"><a-icon type="file-sync" class="mr-2" />Sync Satu Produk</a>
                </a-menu-item> -->
              </a-menu>
            </template>
          </a-dropdown>
        </template>
        <template v-else>
          <a-button
            v-if="['tiktok', 'tiktok_id', 'tokopedia_id', 'shopee_id', 'blibli_id', 'lazada_id'].includes($parent.channel.sales_channel?.code)"
            type="primary"
            size="large"
            class="mr-3"
            :loading="isSyncing[$parent.channel.sales_channel?.code]"
            ghost
            @click="onSyncProduct"
          >
            Sync Produk
          </a-button>
        </template>
        <!-- FIXME: hide edit button for tokopedia channel -->
        <a-button
          v-if="permission.includes('WRITE') && !['blibli_id'].includes($route.query.channel_code)"
          type="primary"
          size="large"
          :loading="isLoading || isSyncing[$parent.channel.sales_channel?.code]"
          @click="onAddProduct"
        >
          {{ $t('product.addProduct') }}
        </a-button>
      </div>
    </div>
    <div class="mt-3">
      <div v-if="viewTab" class="d-flex justify-content-between">
        <a-tabs v-model="activeKey" class="mb-2 pca-tab-store w-75" :disabled="isLoading" @change="onTabChange">
          <a-tab-pane
            v-for="tab in tabs"
            :key="tab"
            :tab="`${$t('store_list_page.' + tab)} (${totalRowData[tab] || 0})`"
          />
        </a-tabs>
        <div class="d-flex align-items-center flexno">
          <b class="mr-2">
            {{ $t('order.sortBy') }}
          </b>
          <a-select style="width: 180px" :value="sortMode" @change="handleChangeSort">
            <a-select-option v-for="sort in SORT_OPTIONS" :key="sort.value" :value="sort.value">
              <a-icon :type="sort.icon" />
              {{ sort.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <a-tabs
        v-if="tabStatusProduct"
        v-model="activeStatus"
        type="card"
        class="mb-2 pca-tab-status"
        :disabled="isLoading"
        @change="handleTabStatus"
      >
        <a-tab-pane v-for="tab in statusProduct" :key="tab" :tab="`${$t('store_list_page.' + tab)}`" />
      </a-tabs>
      <a-table
        class="product-list"
        size="small"
        :data-source="products"
        :row-key="(record) => record.id || record.product_id"
        :columns="columns"
        :expand-icon-as-cell="false"
        :expand-icon-column-index="['distributor', 'store', 'tiktok', 'tiktok_id', 'tokopedia_id', 'shopee_id', 'blibli_id', 'lazada_id'].includes($route.query.channel_code) ? 1 : 0"
        :loading="isLoading"
        :pagination="false"
        :scroll="scroll"
        :row-selection="['distributor', 'store', 'tiktok', 'tiktok_id', 'tokopedia_id', 'shopee_id', 'blibli_id', 'lazada_id'].includes($route.query.channel_code) ? { selectedRowKeys: selectedProducts, onChange: onSelectChange } : null"
      >
        <template slot="expandIcon" slot-scope="props">
          <div class="d-flex align-items-center">
            <div
              style="cursor: pointer;"
              @click="onEditProduct($parent.channel.sales_channel?.code === 'distributor' ? props.record.id : props.record.product_id)"
            >
              <PcaImage
                width="30"
                height="30"
                :src="props.record.images?.[0]?.thumb_url_list?.[0] || props.record.images?.[0]?.url_list?.[0] || props.record.showimg_url || $defaultImage"
                alt=""
                class="product-image mr-3 flexno of-contain"
              />
            </div>
            <div>
              <a-tooltip v-if="props.record.title && props.record.title.length > 22" placement="top">
                <template slot="title">
                  <span>{{ props.record.title }}</span>
                </template>
                <p
                  class="master-title"
                  style="cursor: pointer"
                  @click="onEditProduct($parent.channel.sales_channel?.code === 'distributor' ? props.record.id : props.record.product_id)"
                >
                  {{ `${props.record.title.substring(0, 22)}...` }}
                </p>
              </a-tooltip>
              <p
                v-else
                class="master-title"
                style="cursor: pointer"
                @click="onEditProduct($parent.channel.sales_channel?.code === 'distributor' ? props.record.id : props.record.product_id)"
              >
                {{ props.record.title || '-' }}
              </p>
              <p class="master-sku">
                <span
                  v-if="+props.record.variant_count > 1 && props.record.catalogs.length > 1"
                  @click="e => onOpen(props, e)"
                >
                  {{ $t(`product.${props.record.isOpen ? 'close_variant' : 'view_variant'}`) }}
                </span>
                <template v-else>
                  SKU: {{ props.record.catalogs?.[0]?.sku || '-' }}
                </template>
              </p>
            </div>
          </div>
        </template>
        <template slot="product_name" slot-scope="text">
          {{ text }}
        </template>
        <template slot="category_name" slot-scope="text, record">
          {{ text || (record.category && record.category.name) || '-' }}
        </template>
        <template slot="variant_count" slot-scope="text, record">
          {{ text ? text : Array.isArray(record.catalogs) ? record.catalogs.length : '-' }}
        </template>
        <template slot="price" slot-scope="text, record">
          {{ price(record) }}
        </template>
        <template
          v-if="!['distributor', 'store'].includes($route.query.channel_code)"
          slot="product_status"
          slot-scope="text"
        >
          <a-tag :color="colors[text]">
            {{ $t(`store_list_page.${text?.toLowerCase()}`) }}
          </a-tag>
        </template>
        <template slot="status" slot-scope="text">
          <a-tag :color="colors[text]">
            {{ $t(`store_list_page.${text?.toLowerCase()}`) }}
          </a-tag>
        </template>
        <template slot="import_status" slot-scope="text, record">
          <a-tooltip v-if="text === 'FAILED'" placement="topRight">
            <template slot="title">
              <span>{{
                record.reason_import_status && record.reason_import_status.includes('{')
                  ? JSON.parse(record.reason_import_status)?.message?.toString()
                  : record.reason_import_status
              }}</span>
            </template>
            <a-icon type="exclamation-circle" :style="{ fontSize: '24px', color: 'red' }" />
          </a-tooltip>
        </template>
        <template slot="action" slot-scope="record">
          <div class="text-right">
            <a-dropdown v-if="permission.includes('READ') || permission.includes('WRITE')">
              <a @click.prevent="">
                <a-icon type="more" />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    v-if="permission.includes('READ') || permission.includes('WRITE')"
                    key="1"
                    class="py-2"
                    @click="onEditProduct($parent.channel.sales_channel?.code === 'distributor' ? record.id : record.product_id)"
                  >
                    {{ $t('store_list_page.change_product') }}
                  </a-menu-item>
                  <a-menu-item
                    v-if="permission.includes('READ') || permission.includes('WRITE') && $route.query.channel_code === 'tiktok'"
                    key="2"
                    class="py-2"
                    @click="channelProductId = record.channel_product_id; onSyncProduct()"
                  >
                    Sync Single Produk
                  </a-menu-item>
                  <a-menu-item
                    v-if="$parent.channel.sales_channel?.code !== 'distributor' && permission.includes('WRITE')"
                    key="2"
                    class="py-2"
                    @click="onShowProductModal(record)"
                  >
                    {{ $t('store_list_page.product_mapping') }}
                  </a-menu-item>
                  <a-menu-item
                    v-if="permission.includes('WRITE')"
                    key="3"
                    class="py-2"
                    @click="showModalUpdate(record, true)"
                  >
                    {{ record.product_status === 'ACTIVE' || record.status === 'ACTIVE' ?
                      $t('store_list_page.deactivate_product') : $t('store_list_page.activate_product') }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
        <div v-if="text.catalogs.length > 1" slot="expandedRowRender" slot-scope="text">
          <table class="catalog-list">
            <thead>
              <tr style="border: 1px solid #e4e9f0">
                <th>{{ $t('product.picture') }}</th>
                <th>SKU</th>
                <th v-for="(option, index) in Object.keys(text.catalogs[0].attributes)" :key="index">
                  {{ option }}
                </th>
                <th v-if="['tokopedia_id'].includes($route.query.channel_code)">
                  {{ $t('store_list_page.product_status') }}
                </th>
                <th>{{ $t('store_list_page.mapping_status') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="catalog in text.catalogs" :key="catalog.id" style="border: 1px solid #e4e9f0">
                <td>
                  <PcaImage
                    width="30"
                    height="30"
                    :src="catalog?.images || catalog?.images?.[0] || catalog?.images?.[0]?.thumb_url_list || catalog?.images?.[0]?.url_list || $defaultImage"
                    :alt="catalog.title"
                    class="product-image mr-3 flexno of-contain"
                  />
                </td>
                <td>
                  {{ catalog?.sku || '-' }}
                </td>
                <td v-for="(option, index) in Object.keys(text.catalogs[0].attributes)" :key="index">
                  {{ catalog.attributes[option] }}
                </td>
                <td v-if="['tokopedia_id'].includes($route.query.channel_code)">
                  <a-tag :color="colors[catalog?.is_active ? 'ACTIVE' : 'NOT_ACTIVE']">
                    {{ $t(`store_list_page.${catalog?.is_active ? 'active' : 'not_active'}`) }}
                  </a-tag>
                </td>
                <td>
                  <a-tag :color="colors[catalog?.status]">
                    {{ $t(`store_list_page.${catalog?.status?.toLowerCase()}`) }}
                  </a-tag>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </a-table>
    </div>
    <div class="mt-3 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.limit"
        :page-size-options="sizeOptions"
        :total="props.total_row"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    <div
      v-if="selectedProducts.length > 0 && $route.query.channel_code !== 'distributor'"
      class="d-flex mt-5 py-3 border-top position-sticky footer"
    >
      <div class="mr-5 align-self-center font-weight-bold">
        Produk Terpilih: {{ selectedProducts.length }}
      </div>
      <a-button
        v-if="activeKey === 'unconfirmed'"
        size="large"
        type="primary"
        class="px-5 mr-2"
        :loading="isLoadingImport"
        html-type="submit"
        @click="importProduct"
      >
        Buat Produk Master
      </a-button>
      <a-button
        v-if="channels.includes($route.query.channel_code) && activeKey === 'confirmed'"
        size="large"
        type="primary"
        class="px-5 mr-2"
        @click="visibleSync = true"
      >
        Sync Stok
      </a-button>
    </div>

    <a-modal :visible="visibleUpdate" :footer="false" :closable="false">
      <h3 class="text-center mb-3">
        {{ rowData?.product_status === 'ACTIVE' || rowData?.status === 'ACTIVE' ? 'Nonaktifkan' : 'Aktifkan' }} Produk
      </h3>
      <div class="text-center mt-4">
        <span style="color: #6C757D">
          Apakah Anda yakin ingin {{ rowData?.product_status === 'ACTIVE' || rowData?.status === 'ACTIVE' ?
            'menonaktifkan'
            : 'mengaktifkan' }}
          produk {{ rowData?.title || 'ini' }} ?
        </span>
      </div>
      <div class="text-center mt-5">
        <a-button
          class="inbound__button mx-2 ml-auto"
          :disabled="isSyncing[$parent.channel.sales_channel?.code]"
          @click="showModalUpdate({}, false)"
        >
          Batalkan
        </a-button>
        <a-button
          v-if="['shopee_id', 'tokopedia_id'].includes($parent.channel.sales_channel.code)"
          type="primary"
          :loading="isSyncing[$parent.channel.sales_channel?.code]"
          @click="changeProductChannelStatus"
        >
          Ya
        </a-button>
        <a-button
          v-else
          type="primary"
          :loading="isSyncing[$parent.channel.sales_channel?.code]"
          @click="changeProductStatus"
        >
          Ya
        </a-button>
      </div>
    </a-modal>

    <a-modal
      :title="$t('sideBar.warehouse')"
      :visible="visibleSync"
      width="600px"
      :footer="false"
      :closable="false"
    >
      <p>{{ $t('adjustment.choose_sync_warehouse') }}</p>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
      >
        <a-form-model-item
          prop="warehouses"
          :label="$t('sideBar.warehouse')"
          :rules="{
            required: true,
            trigger: 'change',
            message: 'Gudang harus dipilih'
          }"
        >
          <a-select v-model="form.warehouses" mode="multiple" placeholder="Pilih Gudang" size="large">
            <a-select-option
              v-for="warehouse in warehouseList"
              :key="warehouse.warehouse_master_id"
              :value="warehouse.warehouse_master_id"
              :title="warehouse.warehouse_master_name"
            >
              <div class="d-flex align-items-center">
                <a-tag color="red" style="font-size: 8px; width: 85px;">
                  Gudang Master
                </a-tag>{{ getWarehouseMasterNameById(warehouse.warehouse_master_id) }}
              </div>
              <div class="d-flex align-items-center mt-2">
                <a-tag color="blue" style="font-size: 8px; width: 85px;">
                  Gudang Channel
                </a-tag>{{ warehouse.warehouse_master_name }}
              </div>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <div class="text-right mt-5">
          <a-button
            class="inbound__button mx-2 ml-auto"
            :disabled="isLoadingSync"
            @click="closeModal"
          >
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button
            type="primary"
            :loading="isLoadingSync"
            @click="syncStock"
          >
            Sync Stok
          </a-button>
        </div>
      </a-form-model>
    </a-modal>

    <ModalProductMaster
      :visible="showProductModal"
      :data="detailProduct"
      :channel="$parent.channel.sales_channel?.code"
      :loading-master="loadingMasterModal"
      @add="onAddProduct"
      @cancel="onCloseProductModal"
      @setLoadingMaster="onSetLoadingMaster"
      @successMapping="onSuccessMapping"
    />

    <ModalUploadMaster
      :visible="showUploadModal"
      :selected-products="selectedProducts"
      @cancel="onCloseUploadModal"
      @successUpload="onSuccessUpload"
    />
    <ModalCreate
      :show-modal="showModal"
      :loading="isLoadingMaster"
      :data-product-master="productMasterList"
      :params="paramsMaster"
      :size-option="sizeOptions"
      :pages="$parent.channel.sales_channel?.code"
      @handleModal="handleModal"
      @onPageChangeMaster="onPageChangeMaster"
      @onShowSizeChangeMaster="onShowSizeChangeMaster"
      @onSearchMaster="onSearchMaster"
    />
    <ModalSyncProductBlibliBySKU
      :visible="showModalSyncProductBlibliBySKU"
      @cancel="onCloseModalSyncProductBlibliBySKU"
      @ok="submitModalSyncProductBlibliBySKU"
    />
  </div>
</template>

<script>
import PcaImage from '@/components/Image'
import { columnsProduct } from '@/data/columns'
import debounce from 'lodash/debounce'
import ModalCreate from '@/views/redeem-channel/ModalCreate.vue'
import { getOrderCatalog, getProductMasterList } from '@/api/product'
// Uncomment later when BE is ready
// import { checkTiktokSyncStockStatus } from '@/api/channels/tiktok'
import { getBlibliDataDetail, syncProductBySKU } from '@/api/channels/blibli'
import { activateDeactivateProductTokopedia } from '@/api/channels/tokopedia'
import { getPriceArea } from '@/api/channels/distributor'
import {
  syncProductChannel,
  activateDeactivateProductChannel,
  channelEventProcess,
  channelSyncProcess,
  updateProductChannelStatus,
  importProductChannel,
  getChannelWarehouse,
} from '@/api/channels/index'
import { checkSyncStockStatus, republishChannelStock } from '@/api/inventory'
import XLSX from 'xlsx';
import ModalProductMaster from './components/ModalProductMaster.vue'
import ModalUploadMaster from './components/ModalUploadMaster.vue'
import ModalSyncProductBlibliBySKU from './components/ModalSyncProductBlibliBySKU.vue'
import { getWarehouseList } from '@/api/warehouse'

export default {
  name: 'ProductConfirmation',
  components: {
    PcaImage,
    ModalProductMaster,
    ModalUploadMaster,
    ModalCreate,
    ModalSyncProductBlibliBySKU,
  },
  data() {
    return {
      storeId: null,
      sortMode: 'ASC',
      SORT_OPTIONS: [
        { value: 'ASC', label: 'Status Asc', icon: 'sort-ascending' },
        { value: 'DESC', label: 'Status Desc', icon: 'sort-descending' },
      ],
      keyword: '',
      screenHeight: ((screen.height - 900) * 0.45) + (screen.height * 0.45),
      sorting: 'alphabetical',
      filterStatus: undefined,
      activeKey: 'all',
      activeStatus: 'all',
      showProductModal: false,
      showModal: false,
      showUploadModal: false,
      columns: columnsProduct,
      selectedProducts: [],
      sorts: ['alphabetical', 'numeric'],
      statusImport: ['FINISHED', 'FAILED'],
      statusProduct: ['all', 'active', 'notActive'],
      sizeOptions: ['10', '20', '30', '40', '50'],
      channels: ['blibli_id', 'lazada_id', 'shopee_id', 'tokopedia_id', 'tiktok', 'tiktok_id'],
      detailProduct: {},
      form: {
        warehouses: [],
      },
      colors: {
        CONFIRMED: 'blue',
        ACTIVE: 'blue',
        UNCONFIRMED: 'orange',
        NOT_ACTIVE: 'red',
        INACTIVE: 'red',
        PART_CONFIRMED: 'pink',
        MIX_CONFIRMED: 'purple',
      },
      status: {
        status: '',
        product_status: '',
      },
      params: {
        page: 1,
        limit: 10,
      },
      paramsMaster: {
        page: 1,
        limit: 10,
        total: 0,
      },
      loadingSearch: false,
      loadingMasterModal: false,
      controllerList: null,
      controllerOrderCatalog: null,
      eventProcess: false,
      requestId: '',
      isLoadingSync: false,
      isLoadingMaster: false,
      isLoadingImport: false,
      isLoadingExport: false,
      visibleUpdate: false,
      visibleSync: false,
      isSyncing: {
        distributor: false,
        store: false,
        shopee_id: false,
        tokopedia_id: false,
        tiktok_id: false,
        tiktok: false,
      },
      rowData: {},
      productMasterList: [],
      warehouseList: [],
      search_product: '',
      totalRowData: {
        all: 0,
        unconfirmed: 0,
        confirmed: 0,
        active: 0,
        notactive: 0,
      },
      showModalSyncProductBlibliBySKU: false,
      channelProductId: '',
      listWarehouseMaster: [],
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    isLoading() {
      return this.$store.state.productChannel.loading
    },
    products() {
      let newProducts = this.$store.state?.productChannel?.products?.length
        ? JSON.parse(JSON.stringify(this.$store.state.productChannel.products))
        : []
      if (newProducts.length > 0) {
        newProducts.forEach((product) => {
          const { channel_code: channelCode } = this.$route.query
          // MP tiktok ignore checking status percatalog
          if (['tiktok', 'tiktok_id'].includes(channelCode)) {
            return
          }
          if(product?.catalogs?.length > 1) {
            let countConfirmed = 0
            let countUnconfirmed = 0
            product.catalogs.forEach((catalog) => {
              if(catalog?.status === 'CONFIRMED') {
                countConfirmed++
              } else if(catalog?.status === 'UNCONFIRMED') {
                countUnconfirmed++
              }
            })
            if(countConfirmed > 0 && countUnconfirmed > 0) {
              product.status = 'PART_CONFIRMED'
            } else if(countConfirmed > 0) {
              product.status = 'CONFIRMED'
            } else if(countUnconfirmed > 0) {
              product.status = 'UNCONFIRMED'
            }
          }
        })
      }
      return newProducts
    },
    props() {
      return this.$store.state?.productChannel?.props || {}
    },
    viewTab() {
      const query = this.$route.query
      if (
        query &&
        !['distributor', 'store'].includes(query.channel_code)
      ) {
        return true
      }
      return false
    },
    tabStatusProduct() {
      const query = this.$route.query
      if (
        ['distributor', 'store'].includes(query.channel_code)
      ) {
        return true
      }
      return false
    },
    scroll() {
      return { y: this.screenHeight > 400 ? this.screenHeight : 400, x: 1000 }
    },
    tHeight() {
      return !this.products.length
        ? '0px'
        : this.screenHeight > 400
          ? `${this.screenHeight}px`
          : '400px'
    },
    tabs() {
      if (
        this.$route.query &&
        (this.$route.query.workspace_id.includes('KINO') || this.$route.query.workspace_id.includes('BHS'))
      ) {
        return ['all', 'active', 'notActive']
      }
      return ['all', 'unconfirmed', 'confirmed']
    },
    permission() {
      return this.$store.getters['user/can']('product-list', 'reverse')
    },
  },
  watch: {
    businessId() {
      this.init()
    },
    '$route.query': {
      immediate: true,
      deep: true,
      handler(value) {
        this.keyword = value.q || value.search_query || ''
        this.init()
      },
    },
    '$route.params.id'() {
      this.filterStatus = undefined
      this.sorting = 'alphabetical'
      this.activeKey = 'all'
      this.activeStatus = 'all'
    },
    '$store.state.productChannel.props': {
      immediate: true,
      deep: true,
      handler(value) {
        let all = 0
        if (this.$route.query && this.$route.query.channel_code === 'distributor') {

        } else {
          all = value.confirmed + value.unconfirmed
        }
        this.totalRowData = {
          ...this.totalRowData,
          all: all,
          unconfirmed: value.unconfirmed,
          confirmed: value.confirmed,
        }
      },
    },
  },
  beforeDestroy() {
    // cancel the request
    if (this.controllerList) {
      this.controllerList.abort()
      this.controllerList = null
    }
    if (this.controllerOrderCatalog) {
      this.controllerOrderCatalog.abort()
      this.controllerOrderCatalog = null
    }
  },
  methods: {
    onSetLoadingMaster(value) {
      this.loadingMasterModal = value
    },
    onOpen(props, event) {
      props.record.isOpen = !props.record.isOpen
      props.onExpand(props.record, event)
    },
    addSpace(str, size) {
      let result = '';
      for (let i = 0; i < str.length; i += size) {
        const chunk = str.slice(i, i + size);
        if (!/\s/.test(chunk)) {
          result += chunk + ' ';
        } else {
          result += chunk;
        }
      }
      return result.trim();
    },
    changeStatus() {
      const param = this.$parent.channel.sales_channel?.code === 'distributor' ?
        {
          search_query: this.keyword.trim() || undefined,
          import_status: this.filterStatus,
        } :
        {
          q: this.keyword.trim() || undefined,
          import_status: this.filterStatus,
        }
      this.fetchProduct(param)
    },
    handleModal(param) {
      this.showModal = param
      this.search_product = ''
      this.paramsMaster = {
        page: 1,
        limit: 10,
        total: 0,
      }
      this.fetchProductsMaster()
    },
    showModalUpdate(row, param) {
      this.rowData = row
      this.visibleUpdate = param
    },
    onSearchMaster(e) {
      this.paramsMaster.page = 1
      this.search_product = e
      this.fetchProductsMaster()
    },
    onPageChangeMaster(page) {
      this.paramsMaster.page = page
      this.fetchProductsMaster()
    },
    onShowSizeChangeMaster(size) {
      this.paramsMaster.page = 1
      this.paramsMaster.limit = size
      this.fetchProductsMaster()
    },
    async fetchProductsMaster() {
      this.isLoadingMaster = true
      try {
        const response = await getProductMasterList({
          business_id: this.businessId,
          params: {
            ...this.paramsMaster,
            ...this.$route.query,
            business_id: this.businessId,
            q: this.search_product || undefined,
          },
        })
        this.productMasterList = response.data.data
        this.paramsMaster.total = response.data.total_row
        this.isLoadingMaster = false
      } catch (error) {
        this.isLoadingMaster = false
        console.error(error)
      }
    },
    async syncStock() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let warehouses = []
          let catalogs = []
          this.isLoadingSync = true
          this.selectedProducts.forEach(product => {
            const findProduct = this.products.find(p => p.product_id === product)
            if(findProduct?.product_id && findProduct?.catalogs) {
              const findProductCatalog = findProduct.catalogs.map(catalog => catalog.master_catalog_id)
              catalogs = catalogs.concat(findProductCatalog)
            }
          })

          this.form.warehouses.forEach(warehouse => {
            const findMasterWarehouse = this.warehouseList.find(whs => whs.warehouse_master_id == warehouse)
            if(findMasterWarehouse?.warehouse_id && !warehouses.includes(findMasterWarehouse?.warehouse_id)) {
              warehouses.push(findMasterWarehouse.warehouse_id)
            }
          })
          await republishChannelStock({
            business_id: this.businessId,
            reff_id: this.$moment().format('DoMMMMYYYY'),
            channels: [this.$route.params.id],
            warehouses,
            catalogs,
          })
            .then(({ data: request_id }) => this.syncStockStatus(request_id))
            .catch(err => {
              console.error(err)
              this.isLoadingSync = false
              this.failedSyncStock()
            })
        }
      })
    },
    syncStockStatus: debounce(async function (request_id) {
      await checkSyncStockStatus(request_id)
        .then(({ data: { status } }) => {
          if(status?.toLowerCase() === 'success') {
            this.isLoadingSync = false
            this.closeModal()
            this.$notification.success({
              message: 'Sync Stok Berhasil',
              description: 'Proses sinkronisasi stok ke gudang yang dipilih berhasil',
            })
          } else if(status?.toLowerCase() === 'pending' || status?.toLowerCase() === 'onprogress') {
            this.syncStockStatus(request_id)
          } else {
            this.isLoadingSync = false
            this.failedSyncStock()
          }
        })
        .catch(err => {
          console.error(err)
          this.isLoadingSync = false
          this.failedSyncStock()
        })
    }, 1000),
    async importProduct() {
      try {
        this.isLoadingImport = true
        const channelCode = this.$parent.channel.sales_channel?.code
        const channelId = this.$route.params.id
        const isSingle = channelCode.includes('blibli') && this.selectedProducts.length == 1
          ? 'single/'
          : ''
        const findProduct = isSingle
          ? this.products.find(product => product.product_id === this.selectedProducts[0])
          : {}
        const bodyValue = channelCode.includes('blibli')
          ? {
            channel_id: +channelId,
            product_id: isSingle
              ? this.selectedProducts[0]
              : undefined,
            product_id_list: isSingle
              ? undefined
              : this.selectedProducts,
            catalogs: isSingle
              ? findProduct.catalogs.map(catalog => ({
                catalog_id: catalog.catalog_id,
                master_sku: catalog.sku,
              }))
              : undefined,
          }
          : { product_id: this.selectedProducts }
        const response = await importProductChannel({
          is_single: isSingle,
          business_id: this.businessId,
          channel_code: channelCode,
          channel_id: channelId,
          data: bodyValue,
        })
        if (['tiktok', 'tiktok_id'].includes(channelCode) && ['onprogress', 'on_progress', 'pending', 'on_process'].includes(response?.data?.data?.process_status?.toLowerCase())) {
          return this.checkProcessImportProduct({
            business_id: this.businessId,
            channel_code: channelCode,
            channel_id: channelId,
            request_id: response?.data?.data?.process_id,
          })
        } else {
          if (
            response.data.message?.toLowerCase() === 'success' ||
            response.data.message?.toLowerCase() === 'on_progress' ||
            response.data.status?.toLowerCase() === 'success' ||
            response.data.status?.toLowerCase() === 'on_progress' ||
            response.data.status?.toLowerCase() === 'pending' ||
            ['onprogress', 'on_progress', 'pending', 'success'].includes(response?.data?.data?.process_status?.toLowerCase())
          ) {
            setTimeout(() => {
              this.$notification.success({
                message: 'Produk Sedang Dalam Proses Import ke Master',
              })
              this.isLoadingImport = false
              this.init()
            }, 2000)
          } else if (response.data.status?.toLowerCase() === 'failed') {
            this.isLoadingImport = false
            this.$notification.error({
              message: 'Gagal Mengimport Produk, Silahkan Coba Lagi.',
            })
          }
        }
      } catch (err) {
        console.error({ err })
        this.isLoadingImport = false
        this.$notification.error({
          message: err.message || 'Gagal Mengimport Produk, Silahkan Coba Lagi.',
        })
      }
    },
    checkProcessImportProduct: debounce(function (data) {
      const payload = data
      channelSyncProcess(payload)
        .then(({ data: { data: response } }) => {
          if (['ON_PROGRESS', 'STARTED', 'PENDING', 'ON_PROCESS'].includes(response.process_status)) {
            this.checkProcessImportProduct(payload)
          } else if (['COMPLETED'].includes(response.process_status)) {
            this.$notification.success({
              message: 'Produk Sedang Dalam Proses Import ke Master',
            })
            this.isLoadingImport = false
            this.init()
          } else if (response.process_status === 'FAILED') {
            this.isLoadingImport = false
            this.$notification.error({
              message: 'Gagal Mengimport Produk, Silahkan Coba Lagi.',
            })
          } else if (['REJECTED', 'COMPLETED_WITH_ERROR'].includes(response.process_status)) {
            if (response.process_status === 'COMPLETED_WITH_ERROR') {
              this.init()
            }
            this.isLoadingImport = false
            this.$notification.error({
              message: 'Terjadi Kesalahan',
              description: response.message?.join(', '),
            })
          }
        })
        .catch(() => {
          console.error({ err })
          this.isLoadingImport = false
          this.$notification.error({
            message: err.message || 'Gagal Mengimport Produk, Silahkan Coba Lagi.',
          })
        })
    }, 2000),
    async changeProductChannelStatus() {
      this.isSyncing[this.$parent.channel.sales_channel?.code] = true
      if(this.$parent.channel.sales_channel?.code === 'tokopedia_id') {
        await activateDeactivateProductTokopedia({
          business_id: this.businessId,
          channel_id: this.$route.params.id,
          status: this.rowData.product_status === 'ACTIVE' ? 'deactivate' : 'activate',
          channel_product_id: this.rowData.channel_product_id,
          params: { product_id: this.rowData.product_id },
        })
          .then(({ data: { data } }) => {
            if(data) {
              const parsedData = JSON.parse(data)
              const status = parsedData.data.succeed_rows == 1 ? 'success' : 'error'
              this.showNotification(status)

              if(status == 'success') {
                this.showModalUpdate({}, false)
                this.init()
              }
            } else {
              this.showNotification('error')
            }
          })
          .catch(() => this.showNotification('error'))
          .finally(() => this.isSyncing[this.$parent.channel.sales_channel?.code] = false)
      } else {
        await activateDeactivateProductChannel({
          business_id: this.businessId,
          channel_code: this.$parent.channel.sales_channel?.code,
          channel_id: this.$route.params.id,
          user_id: this.$store.state.user.id,
          product_id: this.rowData.product_id,
          data: {
            section: 1,
            detail: {
              status: this.rowData.product_status === 'ACTIVE' ? 'UNLIST' : 'NORMAL',
            },
          },
        })
          .then(response => this.processChangeStatus(response?.data?.data))
          .catch(() => {
            this.showNotification('error')
            this.isSyncing[this.$parent.channel.sales_channel?.code] = false
          })
      }
    },
    processChangeStatus: debounce(async function (data) {
      await channelEventProcess({
        business_id: this.businessId,
        channel_code: this.$parent.channel.sales_channel?.code,
        channel_id: this.$route.params.id,
        request_id: data.request_id,
      })
        .then(response => {
          if (response.data.status === 'ON_PROGRESS') {
            this.processChangeStatus({ ...response.data, request_id: data.request_id })
          } else if (response.data.status === 'FINISHED') {
            this.showNotification('success')
            this.isSyncing[this.$parent.channel.sales_channel?.code] = false
            this.showModalUpdate({}, false)
            this.init()
          } else if (response.data.status === 'FAILED') {
            this.showNotification('error')
            this.isSyncing[this.$parent.channel.sales_channel?.code] = false
          }
        })
        .catch(() => {
          this.showNotification('error')
          this.isSyncing[this.$parent.channel.sales_channel?.code] = false
        })
    }, 1000),
    async changeProductStatus() {
      this.isSyncing[this.$parent.channel.sales_channel?.code] = true
      const channelCode = this.$parent.channel.sales_channel?.code
      const channelId = this.$route.params.id
      let payload = {
        product_ids: [this.rowData.product_id],
        product_status: this.rowData.product_status === 'ACTIVE' ? 'NON_ACTIVE' : 'ACTIVE',
      }
      const statusChange = this.rowData.status === 'ACTIVE' ? 'deactivate' : 'activate'
      if (this.$parent.channel.sales_channel?.code === 'distributor') {
        payload = {
          product_id: this.rowData.id,
        }
      }
      try {
        const response = await updateProductChannelStatus({
          method: this.$parent.channel.sales_channel?.code === 'distributor'
            ? 'put'
            : 'post',
          business_id: this.businessId,
          channel_code: channelCode,
          channel_id: channelId,
          status: this.$parent.channel.sales_channel?.code === 'distributor'
            ? `${statusChange}`
            : 'status',
          data: payload,
        })
        if (response.data.data.status === "ON_PROGRESS") {
          this.requestId = response.data.data.request_id
          this.getEventProcess(response.data.data.request_id)
        } else if (response.data.message === "Success") {
          this.isSyncing[this.$parent.channel.sales_channel?.code] = false
          this.$notification.success({
            message: 'Status Produk Berhasil Diupdate',
          })
          this.visibleUpdate = false
          this.fetchProduct()
        }
      } catch (e) {
        console.error({ e })
        if (e) {
          this.$notification.error({
            message: e.message || 'Gagal Mengubah Status Product, Silahkan Coba Lagi.',
          })
          this.isSyncing[this.$parent.channel.sales_channel?.code] = false
          this.showModalUpdate({}, false)
        }
      }
    },
    async getSyncProcess(channelCode, channelId, request_id, syncType = '') {
      this.eventProcess = false
      try {
        const response = await channelSyncProcess({
          business_id: this.businessId,
          channel_code: channelCode,
          channel_id: channelId,
          request_id,
        })
        if (response?.data?.status === "ON_PROGRESS" || response?.data?.data?.status === "ON_PROGRESS" || response?.data?.data?.sync_process_state === "ON_PROGRESS" || ['ON_PROCESS', 'PENDING', 'STARTED'].includes(response?.data?.data?.process_status)) {
          this.eventProcess = true
          this.requestId = request_id
          setTimeout(() => {
            this.getSyncProcess(channelCode, channelId, request_id, syncType)
          }, 2000)
        } else if (response?.data?.status === "FINISHED" || response?.data?.data?.status === "FINISHED" || response?.data?.data?.sync_process_state === "FINISHED" || ['COMPLETED', 'FINISHED'].includes(response?.data?.data?.process_status)) {
          this.removeProductChannel(channelCode)
          this.eventProcess = false
          this.requestId = ''
          this.isSyncing[channelCode] = false
          if (!syncType) this.$notification.success({ message: 'Produk Berhasil Disinkronisasi' })
          this.fetchProduct()
        } else if (response.data.status === "FAILED" || response.data.data?.status === "FAILED" || response.data.data?.sync_process_state === "FAILED") {
          this.removeProductChannel(channelCode)
          this.eventProcess = false
          this.isSyncing[channelCode] = false
          if (!syncType) this.$notification.error({ message: response.data.data.message })
        }
      } catch (e) {
        console.error({ e })
        this.removeProductChannel(channelCode)
        if (e.response.status === 404 &&
          (this.$parent.channel.sales_channel?.code === 'tiktok' || this.$parent.channel.sales_channel?.code === 'tiktok_id')) {
          this.eventProcess = true
          this.requestId = request_id
          setTimeout(() => {
            this.getSyncProcess(channelCode, channelId, request_id, syncType)
          }, 2000)
        } else {
          this.eventProcess = false
          this.isSyncing[channelCode] = false
          if (!syncType) this.$notification.error({ message: e.response.data.message || e.response.data.error || e.messagge })
        }
      }
    },
    async getEventProcess(channelCode, channelId, request_id, syncType = '') {
      this.eventProcess = false
      try {
        const response = await channelEventProcess({
          business_id: this.businessId,
          channel_code: channelCode,
          channel_id: channelId,
          request_id,
        })
        if (response.data.status === "FAILED") {
          this.removeProductChannel(channelCode)
          this.eventProcess = false
          this.isSyncing[channelCode] = false
          if (!syncType) this.$notification.error({ message: response.data.message || 'Gagal Diupdate, Coba Lagi!' })
        } if (response.data.status === "ON_PROGRESS") {
          this.eventProcess = true
          this.requestId = request_id
          setTimeout(() => {
            this.getEventProcess(channelCode, channelId, request_id, syncType)
          }, 2000)
        } else if (response.data.status === "FINISHED") {
          this.removeProductChannel(channelCode)
          this.eventProcess = false
          this.requestId = ''
          this.isSyncing[channelCode] = false
          if (!syncType) this.$notification.success({ message: 'Status Produk Berhasil Diupdate' })
          this.visibleUpdate = false
          this.fetchProduct()
        }
      } catch (e) {
        console.error({ e })
        this.removeProductChannel(channelCode)
        if (e.response.status === 404 &&
          (this.$parent.channel.sales_channel?.code === 'tiktok' || this.$parent.channel.sales_channel?.code === 'tiktok_id')) {
          this.eventProcess = true
          this.requestId = request_id
        } else {
          this.eventProcess = false
          this.isSyncing[channelCode] = false
          if (!syncType) this.$notification.error({ message: 'Gagal Sync Product, Coba Lagi.' })
        }
      }
    },
    parseImgSrc(text, record) {
      return text || record.images?.[0]?.thumb_url_list?.[0] || this.$defaultImage
    },
    parseNum(val) {
      return val ? 'IDR ' + val.toLocaleString(this.$store.state?.settings?.locale || 'id-ID') : ''
    },
    onSearch(val) {
      this.loadingSearch = true
      this.params.page = 1
      const param = this.$parent.channel.sales_channel?.code === 'distributor' ? { search_query: val.trim() || undefined } : { q: val.trim() || undefined }
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, ...param },
      })
        .catch(() => {
          this.loadingSearch = false
        })
    },
    price(record) {
      if (record && record.range_price) {
        if (this.parseNum(record.range_price.lowest_price) === this.parseNum(record.range_price.highest_price)) {
          return this.parseNum(record.range_price.lowest_price)
        }
        return [this.parseNum(record.range_price.lowest_price), this.parseNum(record.range_price.highest_price)].filter(Boolean).join(' - ')
      } else if (record.price) {
        if (record.price.min === record.price.max) {
          return this.parseNum(record.price.min)
        }
        return `${this.parseNum(record.price.min)} - ${this.parseNum(record.price.max)}`
      } else {
        return '-'
      }
    },
    init() {
      let columns = []
      columnsProduct.forEach(column => {
        if (['distributor', 'store'].includes(this.$route.query.channel_code)) {
          if (column.key !== 'product_status' && column.key !== 'import_status') {
            columns.push({
              ...column,
              title: column.key === 'status'
                ? this.$t('store_list_page.status')
                : column.title,
            })
          }
        } else {
          columns.push(column)
        }
      })
      this.columns = columns
      this.activeKey = 'all'
      this.selectedProducts = []
      this.params = {
        page: 1,
        limit: 10,
        sort: `product_status,${this.sortMode}`,
      }
      this.status = {
        status: '',
        product_status: '',
      }

      if(this.$parent.channel.sales_channel?.code == 'blibli_id') {
        this.fetchBlibliStoreDetail()
      }

      // Uncomment when BE is ready
      // if((['tiktok', 'tiktok_id']).includes(this.$route.query.channel_code)) this.checkTiktokSyncProducts()
      this.checkSyncProducts()
      this.fetchProduct()
      this.fetchProductsMaster()
      if(this.channels.includes(this.$route.query.channel_code)) {
        this.getWarehouseChannelList()
        this.fetchWarehouseMasterList()
      }
    },
    getWarehouseMasterNameById(id) {
      if (!id || !this.listWarehouseMaster?.length) {
        return ''
      }
      const findWhsMaster = this.listWarehouseMaster.find((whs) => whs.warehouseId === id)
      if (findWhsMaster) {
        return findWhsMaster.warehouseName
      } else {
        return ''
      }
    },
    async fetchBlibliStoreDetail() {
      await getBlibliDataDetail({
        business_id: this.$route.query.business_id,
        channel_id: this.$route.params.id,
      })
      .then(({ data: { data: response } }) => this.storeId = response.store_id)
      .catch(err => {
        this.storeId = null
        console.error(err)
      })
    },
    async fetchProduct(obj) {
      const { sales_channel_id, search_query, q, ...rest } = this.$route.query
      // @NOTE: Dev static shop_id
      const shop_id = this.$parent.channel.sales_channel?.code == 'tiktok' || this.$parent.channel.sales_channel?.code === 'tiktok_id'
        ? 'c9ee7774-8494-45e3-8398-bf3e528be375' : undefined
      const defaultParam = { ...this.params, ...rest, ...obj, business_id: this.businessId, sales_channel_id, shop_id, search_query, q }
      const status = {
        product_status: this.status.product_status || undefined,
        status: this.status.status || undefined,
      }
      this.controllerList = new AbortController()
      await this.$store.dispatch('productChannel/GETPRODUCT', {
        signal: this.controllerList.signal,
        business_id: this.businessId,
        channelId: this.$parent.channel.id,
        channel: this.$parent.channel.sales_channel?.code === 'blibli_id'
          ? 'blibli'
          : this.$parent.channel.sales_channel?.code,
        query: this.$parent.channel.id,
        params: this.$parent.channel.sales_channel?.code === 'blibli_id'
          ? {
              q,
              page: this.params.page,
              limit: this.params.limit,
              product_status: this.status.status || undefined,
              status: (this.status.product_status && this.status.product_status !== 'ACTIVE'
                ? 'REJECTED'
                : this.status.product_status) || undefined,
            }
          : {
            ...defaultParam,
            ...status,
            warehouse_id: this.$store.state.user.restriction_base === 'Warehouse' && this.$route.query?.channel_code === 'distributor'
              ? this.$route.query.warehouse_id
              : undefined,
          },
      })

      this.controllerList = null
      this.loadingSearch = false
    },
    async fetchProductIgnoreCommitState(title, productId) {
      const { sales_channel_id, search_query, q } = this.$route.query
      const shop_id = this.$parent.channel.sales_channel?.code == 'tiktok' || this.$parent.channel.sales_channel?.code === 'tiktok_id'
        ? 'c9ee7774-8494-45e3-8398-bf3e528be375' : undefined
      const defaultParam = { ...this.params, business_id: this.businessId, sales_channel_id, shop_id, search_query, q: title }
      const status = {
        product_status: this.status.product_status || undefined,
        status: undefined,
      }
      this.controllerList = new AbortController()
      return await this.$store.dispatch('productChannel/GETPRODUCTNOTCOMMITSTATE', {
        signal: this.controllerList.signal,
        business_id: this.businessId,
        channelId: this.$parent.channel.id,
        channel: this.$parent.channel.sales_channel?.code === 'blibli_id'
          ? 'blibli'
          : this.$parent.channel.sales_channel?.code,
        query: this.$parent.channel.id,
        productId: productId,
        params: this.$parent.channel.sales_channel?.code === 'blibli_id'
          ? {
              q,
              page: 1,
              limit: 99,
              product_status: undefined,
              status: (this.status.product_status && this.status.product_status !== 'ACTIVE'
                ? 'REJECTED'
                : this.status.product_status) || undefined,
            }
          : {
            ...defaultParam,
            ...status,
            warehouse_id: this.$store.state.user.restriction_base === 'Warehouse' && this.$route.query?.channel_code === 'distributor'
              ? this.$route.query.warehouse_id
              : undefined,
            page: 1,
            limit: 99,
          },
      })
      .then(response => response.filter(product => product?.id === productId || product?.product_id === productId)?.[0] || {})
      .catch(() => {})
      .finally(() => {
        this.controllerList = null
        this.loadingSearch = false
      })
    },
    fetchWarehouseMasterList() {
      const { business_id } = this.$route.query
      getWarehouseList({
        business_id: business_id,
      })
      .then(({ data: resWarehouseMasterList }) => {
        let newWarehouseMasterList = []
        const { data } = resWarehouseMasterList
        if (data?.length > 0) {
          newWarehouseMasterList = data.map(item => {
            return {
              warehouseId: item.id,
              warehouseName: item.name,
            }
          })
          this.listWarehouseMaster = newWarehouseMasterList
        }
      })
      .catch((err) => {
        console.error(err)
        return null
      })
    },
    async getWarehouseChannelList() {
      this.warehouseList = []

      await getChannelWarehouse({
        business_id: this.businessId,
        channel_code: this.$route.query.channel_code === 'blibli_id'
          ? 'blibli'
          : this.$route.query.channel_code,
        channel_id: this.$route.params.id,
        connected: true,
      })
        .then(({ data: response }) => {
          let data
          if (['blibli_id', 'lazada_id', 'tiktok', 'tiktok_id'].includes(this.$route.query.channel_code)) {
            data = response.data
          } else if (['shopee_id', 'tokopedia_id'].includes(this.$route.query.channel_code)) {
            data = response
          } else {
            data = response.data.data
          }
          let warehouse_id
          if (this.$route.query.channel_code === 'blibli_id') {
            warehouse_id = 'master_warehouse_id'
          } else if (this.$route.query.channel_code === 'lazada_id') {
            warehouse_id = 'warehouse_id'
          } else {
            warehouse_id = 'warehouse_master_id'
          }

          let warehouse_master_id
          if (this.$route.query.channel_code === 'lazada_id') {
            warehouse_master_id = 'channel_warehouse_id'
          } else if (['tiktok', 'tiktok_id'].includes(this.$route.query.channel_code)) {
            warehouse_master_id = 'warehouse_master_id'
          } else {
            warehouse_master_id = 'id'
          }

          let warehouse_master_name
          if (['blibli_id', 'lazada_id'].includes(this.$route.query.channel_code)) {
            warehouse_master_name = 'name'
          } else {
            warehouse_master_name = 'warehouse_channel_name'
          }
          data.forEach(warehouse => {
            if(warehouse[warehouse_id]) {
              this.warehouseList.push({
                warehouse_id: warehouse[warehouse_id],
                warehouse_master_id: warehouse[warehouse_master_id],
                warehouse_master_name: warehouse?.[warehouse_master_name] || 'Default Warehouse',
              })
            }
          })
        })
        .catch(err => console.error(err))
    },
    onSuccessMapping: debounce(async function (title, productId) {
      const product = await this.fetchProductIgnoreCommitState(title, productId)
      await Promise.all([this.fetchProduct(), this.onShowProductModal(product)])
    }, 1500),
    closeModal() {
      this.form.warehouses = []
      this.visibleSync = false
      if (this.$refs.ruleForm) this.$refs.ruleForm.resetFields()
    },
    failedSyncStock() {
      this.$notification.error({
        message: 'Sync Stok Gagal',
        description: 'Proses sinkronisasi stok ke gudang yang dipilih gagal',
      })
    },
    handleChangeSort(data) {
      this.sortMode = data
      this.params.page = 1
      this.selectedProducts = []
      this.params.sort = `product_status,${this.sortMode}`
      const param = this.$parent.channel.sales_channel?.code === 'distributor' ? { search_query: this.keyword.trim() || undefined } : { q: this.keyword.trim() || undefined }
      this.fetchProduct(param)
    },
    onTabChange(tab) {
      switch (tab) {
        case 'unconfirmed':
          this.status.status = 'UNCONFIRMED'
          this.status.product_status = this.filterStatus === 'active'
            ? 'ACTIVE'
            : this.filterStatus === 'notActive'
            ? 'NOT_ACTIVE'
            : undefined
          break
        case 'confirmed':
          this.status.status = 'CONFIRMED'
          this.status.product_status = this.filterStatus === 'active'
            ? 'ACTIVE'
            : this.filterStatus === 'notActive'
            ? 'NOT_ACTIVE'
            : undefined
          break
        case 'all':
          this.status.status = undefined
          this.status.product_status = this.filterStatus === 'active'
            ? 'ACTIVE'
            : this.filterStatus === 'notActive'
            ? 'NOT_ACTIVE'
            : undefined
          break
        case 'active':
          this.status.status = 'ACTIVE'
          this.status.product_status = 'ACTIVE'
          break
        case 'notActive':
          this.status.status = 'NOT_ACTIVE'
          this.status.product_status = 'NOT_ACTIVE'
          break
        default:
          this.status.status = undefined
          this.status.product_status = this.filterStatus === 'active'
            ? 'ACTIVE'
            : this.filterStatus === 'notActive'
            ? 'NOT_ACTIVE'
            : undefined
          break
      }
      this.params.page = 1
      this.selectedProducts = []
      const param = this.$parent.channel.sales_channel?.code === 'distributor' ? { search_query: this.keyword.trim() || undefined } : { q: this.keyword.trim() || undefined }
      this.fetchProduct(param)
    },
    handleTabStatus(tab) {
      switch (tab) {
        case 'active':
          this.status.product_status = 'ACTIVE'
          this.status.status = 'ACTIVE'
          break
        case 'notActive':
          this.status.product_status = 'NOT_ACTIVE'
          this.status.status = 'NOT_ACTIVE'
          break
        default:
          this.status.product_status = undefined
          this.status.status = undefined
          break
      }
      this.params.page = 1
      this.selectedProducts = []
      const param = this.$parent.channel.sales_channel?.code === 'distributor' ? { search_query: this.keyword.trim() || undefined } : { q: this.keyword.trim() || undefined }
      this.fetchProduct(param)
    },
    handleFilterStatusProduct() {
      switch (this.filterStatus) {
        case 'active':
          this.status.product_status = 'ACTIVE'
          break
        case 'notActive':
          this.status.product_status = 'NOT_ACTIVE'
          break
        default:
          this.status.product_status = undefined
          break
      }
      this.params.page = 1
      this.selectedProducts = []
      const param = this.$parent.channel.sales_channel?.code === 'distributor' ? { search_query: this.keyword.trim() || undefined } : { q: this.keyword.trim() || undefined }
      this.fetchProduct(param)
    },
    onSelectChange(value) {
      this.selectedProducts = value
    },
    onPageChange(pageNumber) {
      this.params.page = pageNumber
      const param = this.$parent.channel.sales_channel?.code === 'distributor' ? { search_query: this.keyword.trim() || undefined } : { q: this.keyword.trim() || undefined }
      this.fetchProduct(param)
    },
    onShowSizeChange(current, pageSize) {
      this.selectedProducts = []
      this.params.page = current
      this.params.limit = pageSize
      this.fetchProduct()
    },
    async onShowProductModal(item) {
      const ids = (item.catalogs || []).filter(f => f.master_catalog_id) // master_catalog_id | catalog_id
      if (ids.length) {
        this.controllerOrderCatalog = new AbortController()
        this.loadingMasterModal = true
        await getOrderCatalog({
          signal: this.controllerOrderCatalog.signal,
          params: {
            business_id: this.businessId,
            catalogId: ids.map(v => v.master_catalog_id).join(','),
          },
        })
        .then(res => {
          const data = res?.data?.data || []
          if (data.length) {
            this.detailProduct = {
              ...item,
              catalogs: item.catalogs.map(el => {
                const findCatalog = data.find(f => f.id === el.master_catalog_id)
                const catalog_title = findCatalog?.title
                const catalog_sku = findCatalog?.sku
                if (catalog_title) {
                  return {
                    ...el,
                    catalog_title,
                    catalog_sku,
                  }
                }
                return el
              }),
            }
          } else {
            this.detailProduct = item
          }
        })
        .finally(() => {
          this.controllerOrderCatalog = null
          this.loadingMasterModal = false
        })
      } else {
        this.detailProduct = item
        this.loadingMasterModal = false
      }
      this.showProductModal = true
    },
    onCloseProductModal() {
      if (this.controllerOrderCatalog) {
        this.controllerOrderCatalog.abort()
        this.controllerOrderCatalog = null
      }
      this.showProductModal = false
    },
    onShowUploadModal() {
      this.showUploadModal = true
    },
    onCloseUploadModal() {
      this.showUploadModal = false
    },
    onCloseModalSyncProductBlibliBySKU() {
      this.showModalSyncProductBlibliBySKU = false
    },
    submitModalSyncProductBlibliBySKU(params) {
      const payload = {
        ...params,
        store_id: this.storeId,
        channel_id: this.$route.params.id,
      }
      syncProductBySKU({
        business_id: this.businessId,
        data: payload,
      })
        .then(data => {
          if (data.status === 200 && data?.data?.status === 'PENDING') {
            this.$notification.info({
              message: 'Proses Sinkronisasi Produk',
              description: 'Produk sedang dalam proses sinkronisasi. Mohon untuk merefresh browser Anda secara berkala untuk dapat melihat perubahan data produk',
            })
          } else {
            this.$notification.error({
              message: 'Terjadi Kesalahan',
              description: 'Gagal sinkronisasi produk',
            })
          }
          this.showModalSyncProductBlibliBySKU = false
        })
        .catch(err => {
          console.error(err)
          this.showModalSyncProductBlibliBySKU = false
        })
    },
    onSuccessUpload() {
      this.showUploadModal = false
      this.init()
    },
    onAddProduct() {
      if (['tiktok', 'tiktok_id'].includes(this.$parent.channel.sales_channel?.code)) {
        const { workspace_id, business_id } = this.$route.query
        const name = this.$parent.channel.sales_channel?.code !== 'tiktok' && this.$parent.channel.sales_channel?.code !== 'tiktok_id'
          ? this.$parent.channel.sales_channel?.code
          : 'create'
        this.$router.push({
          name: 'product.channel.' + name,
          query: {
            workspace_id,
            business_id,
            channel_code: this.$route.query.channel_code,
            redirect_url: this.$route.fullPath,
            sales_channel_id: this.$route.query.sales_channel_id,
          },
        })
      } else if (['distributor', 'shopee_id', 'tokopedia_id', 'blibli_id'].includes(this.$parent.channel.sales_channel?.code)) {
        this.handleModal(true)
      } else {
        this.$message.warning('Coming Soon!', 5)
      }
    },
    onEditProduct(id) {
      if (['tiktok', 'tiktok_id', 'tokopedia_id', 'shopee_id', 'blibli_id', 'distributor', 'lazada_id'].includes(this.$parent.channel.sales_channel?.code)) {
        const { workspace_id } = this.$route.query
        const routeName = this.$parent.channel.sales_channel?.code === 'tiktok' || this.$parent.channel.sales_channel?.code === 'tiktok_id'
          ? 'product-channel.product-synchronize-edit' :
          this.$parent.channel.sales_channel?.code === 'tokopedia_id'
          ? 'product-channel.product-synchronize-edit-tokped' :
          this.$parent.channel.sales_channel?.code === 'shopee_id'
          ? 'product-channel.product-synchronize-edit-shopee' :
          this.$parent.channel.sales_channel?.code === 'blibli_id'
          ? 'product-channel.product-synchronize-edit-blibli' :
          this.$parent.channel.sales_channel?.code === 'lazada_id'
          ? 'product-channel.product-synchronize-edit-lazada' :
          'product-channel.distributor-product-synchronize-edit'
        this.$router.push({
          name: routeName,
          query: {
            workspace_id,
            business_id: this.businessId,
            id,
            channel_code: this.$parent.channel.sales_channel?.code,
            redirect_url: this.$route.fullPath,
            edit: true,
          },
        })
      } else {
        this.$message.warning('Coming Soon!', 5)
      }
    },
    async onSyncProduct() {
      if (['tiktok', 'tiktok_id', 'tokopedia_id', 'shopee_id', 'blibli_id', 'lazada_id'].includes(this.$parent.channel.sales_channel?.code)) {
        this.isSyncing[this.$parent.channel.sales_channel?.code] = true
        const shop_id = this.$parent.channel.sales_channel?.code === 'tiktok' || this.$parent.channel.sales_channel?.code === 'tiktok_id'
          ? 'c9ee7774-8494-45e3-8398-bf3e528be375' : undefined
        let additionalPayloadtiktokSync = {}
        if (['tiktok', 'tiktok_id'].includes(this.$parent.channel.sales_channel?.code)) {
          additionalPayloadtiktokSync = {
            product_ids: this.channelProductId ? [this.channelProductId] : [],
          }
        }
        await syncProductChannel({
          business_id: this.businessId,
          channel_code: this.$parent.channel.sales_channel?.code === 'blibli_id'
            ? 'blibli'
            : this.$parent.channel.sales_channel?.code,
          channel_id: this.$route.params.id,
          user_id: this.$parent.channel.sales_channel?.code === 'tokopedia_id'
            ? this.$store.state.user.id + '/'
            : '',
          // channel_product_id: this.channelProductId,
          data: {
            store_id: this.$parent.channel.sales_channel?.code === 'blibli_id'
              ? this.storeId
              : undefined,
            shop_id,
            ...additionalPayloadtiktokSync,
          },
        }).then((data) => {
          if (this.$route.query?.channel_code === 'blibli_id' && data?.data?.status === 'PENDING') {
            this.$notification.info({
              message: 'Proses Sinkronisasi Produk',
              description: 'Produk sedang dalam proses sinkronisasi. Mohon untuk merefresh browser Anda secara berkala untuk dapat melihat perubahan data produk',
            })
          } else if (['tiktok', 'tiktok_id'].includes(this.$route.query?.channel_code) && ['ON_PROCESS', 'PENDING'].includes(data?.data?.data?.process_status)) {
            const channelCode = this.$parent.channel.sales_channel?.code
            this.requestId = data?.data?.data?.request_id || data?.data?.data?.process_id
            const channelId = this.$route.params.id
            this.setProductChannel(channelCode, this.requestId)
            setTimeout(() => {
              this.getSyncProcess(channelCode, channelId, data?.data?.data?.request_id || data?.data?.data?.process_id)
            }, 2000)
          } else if (data.data.message === "Success" || data.data?.status?.toLowerCase() === 'processed' || data.data?.data?.status?.toUpperCase() === 'ON_PROGRESS') {
            const channelCode = this.$parent.channel.sales_channel?.code
            const channelId = this.$route.params.id
            this.requestId = this.$parent.channel.sales_channel?.code === 'blibli_id'
              ? data.data.request_id
              : data.data.data.sync_process_id
            this.setProductChannel(channelCode, this.requestId)
            if (['tiktok', 'tiktok_id', 'tokopedia_id', 'blibli_id', 'lazada_id'].includes(channelCode)) {
              setTimeout(() => {
                this.getSyncProcess(channelCode, channelId, this.requestId)
              }, 2000)
            } else if (['shopee_id'].includes(channelCode)) {
              setTimeout(() => {
                this.getEventProcess(channelCode, channelId, this.requestId)
              }, 2000)
            }
          }
        })
          .catch(e => {
            if (e) {
              this.eventProcess = false
              this.isSyncing[this.$parent.channel.sales_channel?.code] = false
            }
          })
      }
    },
    checkSyncProducts() {
      const findSyncProduct = this.$store.state.productChannel.syncProducts.find(product => {
        return product?.code === this.$parent.channel.sales_channel?.code
      })

      if (findSyncProduct?.code) {
        const channelCode = this.$parent.channel.sales_channel?.code
        const channelId = this.$route.params.id
        this.isSyncing[channelCode] = true
        this.requestId = findSyncProduct.request_id

        if (['tiktok', 'tiktok_id', 'tokopedia_id'].includes(findSyncProduct.code)) {
          this.getSyncProcess(channelCode, channelId, findSyncProduct.request_id, 'checking')
        } else if (['shopee_id'].includes(findSyncProduct.code)) {
          this.getEventProcess(channelCode, channelId, findSyncProduct.request_id, 'checking')
        }
      }
    },
    // Uncomment when BE is ready
    // async checkTiktokSyncProducts() {
    //   await checkTiktokSyncStockStatus({
    //     business_id: this.businessId,
    //     channel_code: this.$parent.channel.sales_channel?.code,
    //     channel_id: this.$parent.channel.id,
    //   })
    //   .then(({ data: { data: response } }) => this.isSyncing[this.$parent.channel.sales_channel?.code] = true)
    //   .catch(err => console.error('err', err))
    // },
    setProductChannel(channelCode, request_id) {
      const findSyncProduct = this.$store.state.productChannel.syncProducts.find(product => {
        return product?.code === channelCode
      })
      if (!findSyncProduct?.code) {
        const syncProducts = this.$store.state.productChannel.syncProducts.concat({
          code: channelCode,
          request_id,
        })
        this.$store.commit('productChannel/SET_PRODUCT_CHANNEL', { syncProducts })
      }
    },
    removeProductChannel(channelCode) {
      const syncProducts = this.$store.state.productChannel.syncProducts.filter(product => {
        return product?.code !== channelCode
      })
      this.$store.commit('productChannel/SET_PRODUCT_CHANNEL', { syncProducts })
    },
    showNotification(type) {
      const message = type === 'success' ? 'Berhasil' : 'Gagal'
      this.$notification[type]({
        message,
        description: `Produk ${this.rowData?.title} ${message} ${type === 'success' && this.rowData?.product_status === 'ACTIVE'
          ? 'dinonaktifkan'
          : type !== 'success' && this.rowData?.product_status !== 'ACTIVE'
            ? 'dinonaktifkan'
            : 'diaktifkan'
          }`,
      })
    },
    exportDataTable() {
      this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
        showModalExportSales: true,
        loadingExport: true,
      })
      const { sales_channel_id, business_id } = this.$route.query
      const payload = {
        isExport: true,
        business_id: this.businessId,
        channelId: this.$parent.channel.id,
        channel: this.$parent.channel.sales_channel?.code === 'blibli_id'
          ? 'blibli'
          : this.$parent.channel.sales_channel?.code,
        query: this.$parent.channel.id,
        params: this.$parent.channel.sales_channel?.code === 'blibli_id'
          ? {
            page: 1,
            size: this.props.total_row || 9999,
            channel_id: this.$parent.channel.id,
          }
          : {
            page: 1,
            limit: this.props.total_row,
            sales_channel_id,
            business_id,
          },
      }
      const flattenData = []
      let numbering = 0
      this.$store.dispatch('productChannel/GETPRODUCT', payload)
        .then(async (response) => {
          if (!response.length > 0) return
          await Promise.all(response.map(async (obj, idx) => {
            let productPrice, productId, currency
            if (payload.channel === 'distributor') {
              const { data: { data: response } } = await getPriceArea({
                business_id: this.businessId,
                id: this.$parent.channel.id,
                params: {
                  product_id: obj.id,
                  effective_date: new Date().toISOString(),
                },
              })
              const res = await response.at(0)
              productPrice = {
                min: res?.range_price?.lowest_price,
                max: res?.range_price?.highest_price,
              }
              productId = obj.id
            } else {
              productPrice = {
                min: obj.price?.min,
                max: obj.price?.max,
              }
              productId = obj.product_id
              currency = obj.price?.currency
            }
            let statusProduct
            let statusMapping
            if (['distributor', 'store'].includes(this.$route.query.channel_code)) {
              statusProduct = obj.status
              statusMapping = ''
            } else {
              statusProduct = obj.product_status
              statusMapping = obj.status
            }
            let flattenItem = {
              productId: productId,
              catalogId: '',
              noSku: '',
              noSkuPabrik: '',
              kategori: obj?.category?.name || '',
              namaProduk: obj.title,
              'fotoProduk 1': obj?.showimg_url || '',
              'fotoProduk 2': '',
              'fotoProduk 3': '',
              'fotoProduk 4': '',
              'fotoProduk 5': '',
              opsiVarian: '',
              tipeVarian: '',
              fotoVarian: '',
              mataUang: currency,
              hargaMinimum: productPrice?.min || '',
              hargaMaksimum: productPrice?.max || '',
              uom: '',
              statusProduk: statusProduct,
              statusMapping: statusMapping,
            }
            if (obj.catalogs.length > 0) {
              numbering += 1
              obj.catalogs.forEach(catalog => {
                let currentType = ''
                let currentOption = ''
                if (catalog.options) {
                  Object.entries(catalog.options).forEach(([key, value]) => {
                    currentType = value
                    currentOption = key
                  })
                }
                const newFlattenItem = {
                  catalogId: catalog.catalog_id,
                  namaProduk: catalog?.title,
                  opsiVarian: currentOption,
                  tipeVarian: currentType,
                  noSku: catalog?.sku,
                }
                flattenData.push({
                  no: numbering,
                  ...flattenItem,
                  ...newFlattenItem,
                })
              })
            } else {
              numbering += 1
              flattenData.push({
                no: numbering,
                ...flattenItem,
              })
            }
          }))
          const rowData = []
          const sampleData = flattenData.at(0)
          Object.keys(sampleData).forEach((obj) => {
            rowData.push(this.normalizeObjectKeyToLabel(obj))
          })
          const worksheet = XLSX.utils.json_to_sheet(flattenData);
          const workbook = XLSX.utils.book_new()
          XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: "A1" });
          XLSX.utils.book_append_sheet(workbook, worksheet, 'data')
          XLSX.writeFile(workbook, `${this.$t('product.listProduct')} - ${this.$parent.channel.title}.xlsx`)
          this.$notification.success({
            message: 'Proses export berhasil',
          })
        })
        .catch(() => this.isLoadingExport = false)
        .finally(() => {
          this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
            exportFileNotification: false,
            showModalExportSales: false,
            loadingExport: false,
          })
        })
    },
    normalizeObjectKeyToLabel(text) {
      const result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
  },
}
</script>

<style lang="scss">
.product-list div.ant-table-body {
  min-height: v-bind(tHeight);
}

.ant-table-row {
  background-color: #fff !important;

  &.ant-table-row-selected>td {
    background-color: #fff !important;
  }
}

.pca-tab-store {
  .ant-tabs-bar {
    margin-bottom: 0;
    border-bottom-color: transparent;
  }

  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }

  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}

.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}

.pca-tab-status {
  .ant-tabs-bar {
    margin-bottom: 0;
    border-bottom-color: transparent;
  }

  .ant-tabs-tab {
    padding: 0 25px !important;
    border-radius: 20px !important;
  }

  .ant-tabs-tab-active {
    background: #ff0a54 !important;
    color: #fff !important;
  }
}

.inbound__button {
  color: #ff0854 !important;
  background: #fff !important;
  border-color: #ff0854 !important;
}

.footer {
  bottom: 0;
  background-color: #F8F9FA;
  z-index: 3;
}

.catalog-list {
  border: 1px solid #e4e9f0;

  tr>th {
    padding: 10px;
    font-weight: 500;
    background-color: #f8f9fa;
  }

  tr>td {
    padding: 5px 10px;
    background-color: #fff;
  }

  tr>th,
  tr>td {
    border-bottom: 1px solid #e4e9f0;
  }
}

.master-title {
  font-weight: 500;
  color: #ff0a54;
}

.master-sku {
  font-size: 12px;

  span {
    font-weight: 600;
    color: #2196F3;
    cursor: pointer
  }
}

.master-brand,
.master-title,
.master-sku {
  margin: 0;
}

.ant-table-expanded-row>td {
  padding: 16px 20px !important;
}
</style>
